<script setup lang="ts">
const props = defineProps({
  user: {
    type: Object as PropType<ReturnType<typeof useAuth>['data']['value']>,
    default: null,
    required: false
  },
  size: {
    type: String as PropType<'small' | 'large'>,
    default: 'small',
    required: false
  }
})

const avatarUrl = computed(() => {
  if (props.user?.profile_image) {
    return props.user?.profile_image.presigned_get_object_url
  }
  return null
})

const computedSize = computed(() => {
  if (props.size === 'small') {
    return 36
  }
  return 80
})
</script>

<template>
  <div v-if="avatarUrl" :style="{ height: computedSize + 'px', width: computedSize + 'px' }">
    <nuxt-picture
      v-if="avatarUrl"
      :img-attrs="{ class: 'rounded-full' }"
      :src="avatarUrl"
      alt="Rounded avatar"
      :width="computedSize"
      :height="computedSize"
    />
  </div>
  <div
    v-else
    class="flex overflow-hidden rounded-full bg-gray-20 text-center"
    :style="{ height: computedSize + 'px', width: computedSize + 'px' }"
  >
    <div
      class="text-gray-60"
      :style="{
        width: computedSize + 'px',
        height: computedSize + 'px'
      }"
    >
      <IconsFigmaAccountFilled />
    </div>
  </div>
</template>
